import { Warning } from '@material-ui/icons';
import { loadStripe } from '@stripe/stripe-js';
import React, { useEffect, useState } from 'react';

import Loading from '../../../components/Loading';
import Modal from '../../../components/Modal/Modal';
import {
  ActiveRenewalFailureData,
  CompletionData,
  PaymentMethod,
  StripeData,
  useCreatePaymentSessionMutation,
  useSendInvoiceLinkEmailMutation
} from '../../../generated/graphql';
import getEnv from '../../../utils/getEnv';
import { PaymentCompleteMessage } from '../../Complete/TnCSignedFlow';

const NAME = 'UnableToChargeModal';

const {
  REACT_APP_STRIPE_CA_PUBLISHABLE_KEY,
  REACT_APP_STRIPE_US_PUBLISHABLE_KEY
} = getEnv();

interface UnableToChargeModal {
  activeRenewalFailureData: ActiveRenewalFailureData | undefined;
  paymentMethod: PaymentMethod | undefined;
}

const UnableToChargeModal: React.FC<UnableToChargeModal> = ({
  activeRenewalFailureData,
  paymentMethod
}) => {
  if (!activeRenewalFailureData || !paymentMethod) {
    throw new Error(
      'activeRenewalFailureData or paymentMethod is required in UnableToChargeModal'
    );
  }
  const [stripeData, setStripeData] = useState<StripeData | undefined>(
    undefined
  );
  const [completionData, setCompletionData] = useState<
    CompletionData | undefined
  >(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const { __typename, ...checkoutInfo } = activeRenewalFailureData;
  const { encryptedData } = checkoutInfo;
  if (__typename !== 'ActiveRenewalFailureData') {
    throw new Error(
      'ActiveRenewalFailureData is invalid in UnableToChargeModal'
    );
  }

  const [createPaymentSession] = useCreatePaymentSessionMutation();
  const [sendInvoiceLink] = useSendInvoiceLinkEmailMutation();

  const fetchContinuePaymentSession = async () => {
    // eslint-disable-next-line no-console
    console.log('fetchContinuePaymentSession is called');
    try {
      const {
        data: responseFromContinuePaymentSession
      } = await createPaymentSession({
        variables: {
          isContinuing: true,
          checkoutInfo
        }
      });
      if (!responseFromContinuePaymentSession) {
        throw new Error(
          'responseFromContinuePaymentSession should be ready in UnableToChargeModal'
        );
      }
      if (
        responseFromContinuePaymentSession.createPaymentSession.__typename ===
        'StripeData'
      ) {
        setStripeData(responseFromContinuePaymentSession.createPaymentSession);
        setCompletionData(undefined);
      } else if (
        responseFromContinuePaymentSession.createPaymentSession.__typename ===
        'CompletionData'
      ) {
        setStripeData(undefined);
        setCompletionData(
          responseFromContinuePaymentSession.createPaymentSession
        );
      }
    } catch (error) {
      throw new Error(
        `${NAME}: unable to continue to the Stripe payment checkout screen due to the issue ${error}`
      );
    }
  };

  const fetchSendInvoiceLink = async () => {
    setIsLoading(true);
    try {
      const { data: sendInvoiceLinkResponse } = await sendInvoiceLink({
        variables: {
          encryptedString: encryptedData
        }
      });
      setIsLoading(false);
      if (
        sendInvoiceLinkResponse &&
        sendInvoiceLinkResponse.sendInvoiceLinkEmail
      ) {
        window.alert('Invoice link has been sent to the client');
      } else {
        window.alert('Failed to send the invoice link');
      }
    } catch (error) {
      setIsLoading(false);
      throw new Error(
        `${NAME}: unable to send the invoice link due to the issue ${error}`
      );
    }
  };

  const onClickProceedToStripe = () => {
    fetchContinuePaymentSession();
  };

  const onClickSendInvoiceLink = () => {
    fetchSendInvoiceLink();
    // eslint-disable-next-line no-console
    console.log('sendInvoiceLink()');
  };

  useEffect(() => {
    if (stripeData) {
      redirectToStripe(stripeData);
    }
  }, [stripeData]);

  if (isLoading) {
    return <Loading />;
  }

  const redirectToStripe = async (stripeData: StripeData) => {
    const publishableKey =
      stripeData.stripePlatformAccount === 'StripePlatformAccount-US'
        ? REACT_APP_STRIPE_US_PUBLISHABLE_KEY
        : REACT_APP_STRIPE_CA_PUBLISHABLE_KEY;
    const stripe = await loadStripe(publishableKey);
    if (stripe) {
      // Stripe API call to redirect to a new session
      await stripe.redirectToCheckout({
        sessionId: stripeData.session_id
      });
    } else {
      throw new Error(`unreachable: Stripe Redirect failed`);
    }
  };

  return (
    <>
      {completionData ? (
        <PaymentCompleteMessage policyFoxdenId={completionData.policyNumber} />
      ) : (
        <div
          className="UnableToChargeModal w-full"
          data-testid="UnableToChargeModal"
        >
          <Modal>
            <div className="px-4 py-4 flex flex-col items-center">
              <Warning
                style={{
                  color: '#FFD64E',
                  fontSize: '96px'
                }}
              />
              <h1 className="text-4xl font-bold my-8">
                Unable to Charge Credit Card
              </h1>
              <p className="my-2 text-xl">
                Our payment system was not able to successfully process the
                payment using the credit card provided.
              </p>
              <p className="my-2 text-xl">
                You may try to proceed by manually entering the credit card info
                with Stripe or by sending an invoice to the client.
              </p>
              <div className="mt-8">
                <button
                  className="px-12 py-3 text-2xl font-bold text-white rounded-md py-43 bg-stripe"
                  data-testid="proceedToStripeButton"
                  onClick={onClickProceedToStripe}
                >
                  Proceed to Stripe
                </button>
              </div>
              <div className="mt-8">
                <button
                  className="px-12 py-3 text-2xl font-bold text-white rounded-md py-43 bg-tertiary"
                  data-testid="sendInvoiceToClientButton"
                  onClick={onClickSendInvoiceLink}
                >
                  Send Invoice to Client
                </button>
              </div>
            </div>
          </Modal>
        </div>
      )}
    </>
  );
};

export default UnableToChargeModal;
